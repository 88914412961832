import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Alert, Button, Dropdown} from 'react-bootstrap';
import TopNavbar from '../components/TopBar/TopBar';
import {handleFetchTests} from '../handlers/testHandlers';
import SkeletonCard from '../components/card/SkeletonTestCard';
import {useNavigate} from 'react-router-dom';
import TestCard from '../components/card/TestCard';
import { ReactComponent as ReactLogo } from '../assets/svg/sorry.svg';


const TestList = () => {
    const [tests, setTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchTests(setTests, setIsLoading, setError, setFilters);
    }, []);

    useEffect(() => {
        if (filters.length > 0) {
            setCategories(filters.map(filter => filter.category));
        }
    }, [filters]);

    useEffect(() => {
        if (selectedCategory) {
            const category = filters.find(filter => filter.category === selectedCategory);
            setSubcategories(category ? category.subcategories : []);
        } else {
            setSubcategories([]);
        }
    }, [selectedCategory, filters]);

    const handleFilterChange = (category, subcategory) => {
        setIsLoading(true);
        handleFetchTests(setTests, setIsLoading, setError, setFilters, category, subcategory);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
        handleFilterChange(category, null);
    };

    const handleSubcategorySelect = (subcategory) => {
        setSelectedSubcategory(subcategory);
        handleFilterChange(selectedCategory, subcategory);
    };

    const handleBack = () => {
        navigate(-1);  // Navigate to the previous page
    };

    if (error) {
        return (
            <>
                <TopNavbar/>
                <Container>
                    <Alert variant="danger" className="mt-3">
                        <Alert.Heading>Error: {error}</Alert.Heading>
                        <p>
                            There was an error fetching the test data. Please try reloading the page
                            or go back to the previous page.
                        </p>
                        <div className="d-flex justify-content-end">
                            <Button onClick={handleBack} variant="outline-success">
                                Go Back
                            </Button>
                        </div>
                    </Alert>
                </Container>
            </>
        );
    }

    return (
        <>
            <TopNavbar/>
            <Container fluid style={{position: 'relative', padding: '0 15px'}}>
                <Row className="justify-content-end mb-3">
                    <Col xs="auto">
                        <Dropdown onSelect={handleCategorySelect}>
                            <Dropdown.Toggle variant="dark" id="dropdown-category">
                                {selectedCategory ? selectedCategory : "Category"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>----</Dropdown.Item>
                                {categories.map((category, idx) => (
                                    <Dropdown.Item key={idx} eventKey={category}>
                                        {category}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col xs="auto">
                        <Dropdown onSelect={handleSubcategorySelect} disabled={!selectedCategory}>
                            <Dropdown.Toggle variant="dark" id="dropdown-subcategory">
                                {selectedSubcategory ? selectedSubcategory : "Subcategory"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={null}>----</Dropdown.Item>
                                {subcategories.map((subcategory, idx) => (
                                    <Dropdown.Item key={idx} eventKey={subcategory}>
                                        {subcategory}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                {isLoading && (
                    <div className="loading-banner" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1050
                    }}>
                    </div>
                )}
                {tests.length > 0 || isLoading ? (
                    <Row>
                        {(isLoading ? Array.from({length: 12}) : tests).map((test, idx) => (
                            <Col xs={12} md={6} lg={3} key={idx} className="mb-4">
                                {isLoading ? <SkeletonCard/> : (
                                    <TestCard
                                        id={test.uuid}
                                        title={test.name}
                                        category={test.category_name}
                                        subcategory={test.subcategory_name}
                                        difficulty={test.difficulty}
                                    />
                                )}
                            </Col>
                        ))}
                    </Row>
                ) : <div><Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <ReactLogo style={{ width: '100px', height: '100px' }} />
                        <h2 className="mt-4">No Tests Available</h2>
                        <p className="lead">Sorry, there are no tests available at the moment. Please check back later
                            or adjust your filters.</p>
                    </Col>
                </Row></div>}
            </Container>
        </>
    );
};

export default TestList;
