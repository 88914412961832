import React from "react";

const SkeletonCard = () => {
    return (
        <div className="card skeleton mb-4" style={{ width: '100%', height: '320px' }}>
            <div className="card-img-top" style={{ backgroundColor: '#e1e1e1', height: '180px' }}></div>
            <div className="card-body">
                <h5 className="card-title placeholder-glow">
                    <span className="placeholder col-6"></span>
                </h5>
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-7"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-4"></span>
                    <span className="placeholder col-6"></span>
                    <span className="placeholder col-8"></span>
                </p>
            </div>
        </div>
    );
};

export default SkeletonCard;