import TopBar from "../../components/TopBar/TopBar";
import BreadcrumbNav from "../../components/BreadcrumbNav/BreadcrumbNav";
import DataTableDetailed from "../../components/DataTable/DataTableDetailed";

const TestResultDetail = () => {
    return (
        <>
            <TopBar/>
            <BreadcrumbNav/>
            <DataTableDetailed/>
        </>
    );
};

export default TestResultDetail;