import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './DoughnutChart.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartCard = ({ data, isLoading }) => {
    const chartData = {
        labels: ['Passed', 'In Progress', 'Failed'],
        datasets: [
            {
                label: 'Test Results',
                data: data || [],
                backgroundColor: ['#1cc88a', '#ffc107', '#dc3545'],
                borderColor: ['#ffffff'],
                borderWidth: 2,
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom'
            }
        }
    };

    return (
        <Card className="mb-4 shadow-sm">
            <Card.Header as="h6" className="rounded-header text-dark fw-bold">Test Results</Card.Header>
            <Card.Body>
                {isLoading ? <Skeleton height={300} /> : <Doughnut data={chartData} options={options} />}
            </Card.Body>
        </Card>
    );
};

export default DoughnutChartCard;
