import React from 'react';
import {Card, Col, ProgressBar, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBrain, faChartLine, faList} from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './StatCard.css';

const InfoCard = ({title, value, type, icon, isLoading}) => {
    const getCardContent = () => {
        if (isLoading) {
            return <Skeleton height={30}/>;
        }
        switch (type) {
            case 'progress':
                let variantClass;
                if (value < 25) {
                    variantClass = 'progress-bar-danger';
                } else if (value < 50) {
                    variantClass = 'progress-bar-warning';
                } else if (value < 80) {
                    variantClass = 'progress-bar-info';
                } else {
                    variantClass = 'progress-bar-success';
                }
                return (
                    <Row className="align-items-center">
                        <Col xs="auto"><span className="percentage-label">{value}%</span></Col>
                        <Col>
                            <ProgressBar now={value} className={variantClass}/>
                        </Col>
                    </Row>
                );
            case 'percentage':
            case 'text':
                if (value) {
                    return <span className="h5">{value}</span>;
                } else {
                    return <span className="h5">N/A</span>;
                }

            default:
                return <span className="h5">N/A</span>;
        }
    };

    const getIcon = () => {
        if (isLoading) {
            return <Skeleton circle={true} height={40} width={40}/>;
        }
        switch (icon) {
            case 'database':
                return <FontAwesomeIcon icon={faBrain} size="2x" color="darkgray"/>;
            case 'chart':
                return <FontAwesomeIcon icon={faChartLine} size="2x" color="darkgray"/>;
            case 'list':
                return <FontAwesomeIcon icon={faList} size="2x" color="darkgray"/>;
            default:
                return null;
        }
    };

    return (
        <Card className="info-card mb-4 shadow-sm">
            <Card.Body>
                <Card.Title className="fw-bolder mb-2">{isLoading ? <Skeleton width={150}/> : title}</Card.Title>
                <div className="d-flex justify-content-between align-items-center">
                    <div style={{flexGrow: 1}}>
                        {getCardContent()}
                    </div>
                    <div className="ms-3">
                        {getIcon()}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default InfoCard;
