import React, { useState, useEffect } from 'react';
import { Card, Table, Form, Row, Col, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'; // Ensure you have this package installed
import "./DataTable.css";
import fetchData from "../../fetchers/fetchTestResult";

const TableCard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [recordsToShow, setRecordsToShow] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const loadedData = await fetchData();
            setData(loadedData);
            setLoading(false);
        };
        getData();
    }, []);

    const handleButtonClick = (uuid) => {
        navigate(`/test-results/${uuid}/`);
    };

    if (loading) {
        return (
            <Container fluid style={{position: 'relative', padding: '0 2%'}}>
                <Skeleton count={10} />
            </Container>
        );
    }

    return (
        <Container fluid style={{position: 'relative', padding: '0 2%'}}>
            <Row className="mb-4">
                <Col>
                    <Card className="shadow">
                        <Card.Header>
                            <p className="text-dark m-0 fw-bold">Completed Tests</p>
                        </Card.Header>
                        <Card.Body>
                        <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="6">
                                    Show
                                </Form.Label>
                                <Col sm="6">
                                    <Form.Control
                                        as="select"
                                        value={recordsToShow}
                                        onChange={e => setRecordsToShow(e.target.value)}
                                        custom
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Table bordered hover responsive className="text-black">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Verdict</th>
                                    <th>Score</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.slice(0, recordsToShow).map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.test_name}</td>
                                        <td>{item.category_name}</td>
                                        <td style={{color: item.verdict === 'Passed' ? 'green' : item.verdict === 'Failed' ? 'red' : undefined}}>
                                            {item.verdict || "N/A"}
                                        </td>
                                        <td>{item.score || "N/A"}</td>
                                        <td className={`${
                                            item.status === 'Pending' ? 'text-danger' :
                                                item.status === 'Checking' ? 'text-warning' :
                                                    item.status === 'Checked' ? 'text-success' : ''
                                        }`}>
                                            {item.status}
                                        </td>
                                        <td>{item.date_completed}</td>
                                        <td>
                                            <Button
                                                variant="dark"
                                                onClick={() => handleButtonClick(item.uuid)}
                                                disabled={item.status !== "Checked"}
                                            >
                                                Show
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TableCard;
