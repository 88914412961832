import React from 'react';
import { Card, Button } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";

const TestCard = ({ title = "Test Title", category, subcategory, difficulty = "N/A" , id}) => {
    const navigate = useNavigate();

    const handleStartTest = () => {
        console.log(id)
        navigate(`/tests/${id}`);
    };

    return (
        <Card className="mb-4" style={{ width: '100%' }}> {/* Added mb-4 for margin bottom */}
            <Card.Img variant="top" src="https://i1.wp.com/antitreningi.ru/info/wp-content/uploads/2021/05/%D0%9E%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%BA%D1%83%D1%80%D1%81.png?fit=1000%2C500&ssl=1" />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    <strong>Category:</strong> {category}<br />
                    <strong>Subcategory:</strong> {subcategory}<br />
                    <strong>Difficulty:</strong> {difficulty}
                </Card.Text>
                <Button variant="btn btn-dark" onClick={handleStartTest}>Start test</Button>
            </Card.Body>
        </Card>
    );
};

export default TestCard;
