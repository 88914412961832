import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import './DetailInfoModal.css';

const DetailInfoModal = ({ show, handleClose, data }) => {
    if (!data || data.length === 0) {
        return null;
    }

    const { question_text, answer_text, is_correct, category_name, subcategory_name, helpful_urls, answer_explanation } = data[0];

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>DETAIL</span>
                        {/*<button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>*/}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Question:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {question_text}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Answer:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {answer_text}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Explanation:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {answer_explanation ? answer_explanation : "N/A"}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Correct:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {is_correct ? 'True' : 'False'}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Category:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {category_name}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Subcategory:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {subcategory_name}
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="fw-bold text-dark">
                        Helpful URLs:
                    </Col>
                    <Col xs={8} className="text-secondary">
                        {helpful_urls && helpful_urls.length > 0 ? (
                            helpful_urls.map((url, index) => (
                                <div key={index}>
                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                        {url}
                                    </a>
                                </div>
                            ))
                        ) : (
                            "N/A"
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DetailInfoModal;
