import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Button} from 'react-bootstrap';
import './CompletionPage.css';
import TopNavbar from "../../components/TopBar/TopBar";

const CompletionPage = () => {
    const navigate = useNavigate();
    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        // Delay the appearance of the buttons
        const timer = setTimeout(() => setShowButtons(true), 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <TopNavbar/>
            <Container className="completion-container">
                <div className="checkmark-circle">
                    <div className="checkmark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle cx="26" cy="26" r="45" fill="none"/>
                            <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div>
                </div>
                <div className="completion-message">
                    The test result has been successfully saved. Detailed information and points will be available as soon as possible.
                </div>
                {showButtons && (
                    <div className="button-group">
                        <Button variant="dark" onClick={() => navigate('/dashboard')}>Go to Dashboard</Button>
                    </div>
                )}
            </Container>
        </>
    );
};

export default CompletionPage;
