// src/handlers/testHandlers.js
import { fetchTests } from '../api/tests/getTests';
import {fetchTestAndQuestions} from "../api/tests/getTestAndQuestions";
import axios from "../api/axios";

// handlers/testHandlers.js
// import axios from 'axios';

export const handleFetchTests = async (setTests, setIsLoading, setError, setFilters, category = null, subcategory = null) => {
    try {
        const response = await axios.get('/tests', {
            params: {
                category,
                subcategory
            }
        });
        setTests(response.data.tests);
        setFilters(response.data.filters);
        setIsLoading(false);
    } catch (error) {
        setError(error.message);
        setIsLoading(false);
    }
};


// export const handleFetchFilters = async (setCategories, setError) => {
//     try {
//         const response = await axios.get('/filters');
//         setCategories(response.data.filters);
//     } catch (error) {
//         setError(error.message);
//     }
// };
