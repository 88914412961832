import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Nav, Alert, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TopNavbar from "../../components/TopBar/TopBar";
import axios from "../../api/axios";
import './TestQuestions.css';

const TestQuestions = () => {
    const { testId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showWarningAlert, setShowWarningAlert] = useState(false);
    const [showFinishModal, setShowFinishModal] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [testResultUUID, setTestResultUUID] = useState(null);
    const timerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`/tests/start`, { "test_uuid": testId })
            .then(response => {
                setQuestions(response.data.questions);
                const durationInSeconds = parseTimeString(response.data.test.duration);
                setRemainingTime(durationInSeconds);
                setTestResultUUID(response.data.test_result_uuid);
                setIsLoading(false);
            })
            .catch(error => {
                setError(`Failed to fetch test questions: ${error.message}`);
                setIsLoading(false);
            });
    }, [testId]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = '';
            setShowWarningAlert(true);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (remainingTime > 0) {
            timerRef.current = setInterval(() => {
                setRemainingTime(prev => {
                    if (prev <= 1) {
                        clearInterval(timerRef.current);
                        alert("Time is up!");
                        navigate('/completed');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerRef.current);
    }, [remainingTime, navigate]);

    const parseTimeString = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return (hours * 3600) + (minutes * 60) + (seconds || 0);
    };

    const handleAnswerSubmit = () => {
        const currentQuestion = questions[currentQuestionIndex];
        const payload = {
            text: answers[currentQuestion.uuid]?.answer || '',
            question_uuid: currentQuestion.uuid,
            test_result_uuid: testResultUUID,
        };

        axios.patch('/answers/test_answer', payload)
            .then(() => {
                setAnswers(prevAnswers => ({
                    ...prevAnswers,
                    [currentQuestion.uuid]: { ...prevAnswers[currentQuestion.uuid], submitted: true }
                }));
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                }, 3500);

                const nextUnansweredIndex = questions.findIndex((q, index) =>
                    !answers[q.uuid]?.submitted && index > currentQuestionIndex
                );

                if (nextUnansweredIndex !== -1) {
                    setCurrentQuestionIndex(nextUnansweredIndex);
                } else {
                    const nextIndex = questions.findIndex(q => !answers[q.uuid]?.submitted);
                    if (nextIndex !== -1) {
                        setCurrentQuestionIndex(nextIndex);
                    }
                }
            })
            .catch(error => {
                setError(`Error submitting answer: ${error.message}`);
            });
    };

    const handleTestFinish = () => {
        if (questions.some(q => !answers[q.uuid]?.submitted)) {
            setShowFinishModal(true);
        } else {
            finishTest();
        }
    };

    const finishTest = () => {
        axios.post(`/tests/finish`, { "test_uuid": testResultUUID })
            .then(() => {
                navigate('/completed');
            })
            .catch(error => {
                setError(`Error finishing test: ${error.message}`);
            });
    };

    const handleInputChange = (questionId, value) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: { answer: value, submitted: prevAnswers[questionId]?.submitted || false }
        }));
    };

    const renderSkeleton = () => (
        <>
            <Card>
                <Card.Body>
                    <Card.Title><Skeleton width={100} /></Card.Title>
                    <Card.Text>
                        <Skeleton count={3} />
                    </Card.Text>
                    <Form>
                        <Form.Group controlId="answerInput">
                            <Form.Label>Your answer:</Form.Label>
                            <Form.Control type="text" disabled autoComplete="off" />
                        </Form.Group>
                        <Button variant="primary" disabled className="mt-3">
                            <Skeleton width={70} height={30} />
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );

    if (isLoading) {
        return (
            <div>
                <TopNavbar />
                <Container className="mt-4">
                    {renderSkeleton()}
                </Container>
            </div>
        );
    }

    if (error) {
        return (
            <>
                <TopNavbar />
                <Container><Alert variant="danger">Error: {error}</Alert></Container>
            </>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];
    const currentAnswer = answers[currentQuestion.uuid]?.answer || '';
    const isSubmitted = answers[currentQuestion.uuid]?.submitted || false;
    const allAnswered = questions.length > 0 && questions.every(q => answers[q.uuid]?.submitted);

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <>
            <TopNavbar />
            <Container className="mt-4">
                {showSuccessAlert && (
                    <Alert variant="success" className="custom-alert">
                        The answer has been successfully saved!
                    </Alert>
                )}
                {showWarningAlert && (
                    <Alert variant="warning" className="custom-alert">
                        Warning: If you reload or navigate away, your progress will be lost!
                    </Alert>
                )}
                <Row>
                    <Col xs={8}>
                        <Nav variant="pills" className="flex-row mb-3 dark">
                            {questions.map((question, index) => (
                                <Nav.Item key={question.uuid} className="nav-item-custom">
                                    <Nav.Link
                                        active={index === currentQuestionIndex}
                                        onClick={() => setCurrentQuestionIndex(index)}
                                        className={answers[question.uuid]?.submitted ? 'nav-link-custom answered' : 'nav-link-custom'}
                                    >
                                        {index + 1}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col xs={4} className="d-flex align-items-center justify-content-center">
                        <div className="timer">
                            Time Remaining: {formatTime(remainingTime)}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Question number: {currentQuestionIndex + 1}</Card.Title>
                                <Card.Text>{currentQuestion.text}</Card.Text>
                                <Form>
                                    <Form.Group controlId="answerInput">
                                        <Form.Label>Your answer:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentAnswer}
                                            onChange={(e) => handleInputChange(currentQuestion.uuid, e.target.value)}
                                            disabled={isSubmitted}
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="dark"
                                            onClick={handleAnswerSubmit}
                                            className="mt-3"
                                            disabled={isSubmitted}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant={allAnswered ? "success" : "danger"}
                                            onClick={handleTestFinish}
                                            className="mt-3"
                                        >
                                            Finish Test
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal show={showFinishModal} onHide={() => setShowFinishModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Incomplete Test</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Not all questions have been answered. Do you want to continue or finish the test?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFinishModal(false)}>
                        Go Back
                    </Button>
                    <Button variant="danger" onClick={finishTest}>
                        Finish Test
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TestQuestions;
