import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Table, Container, Button, Row, Form, Col, Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import "./DataTable.css";
import DetailInfoModal from "../DetailInfoModal/DetailInfoModal";
import axios from "../../api/axios";

const DataTableDetailed = () => {
    const { testResultUuid } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [recordsToShow, setRecordsToShow] = useState(10);
    const [modalData, setModalData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.post('/test_results/answers', {
                    test_result_uuid: testResultUuid
                });
                setData(response.data);
            } catch (error) {
                setError('Failed to load data. Please try again.');
                setTimeout(() => navigate(-1), 3000); // Navigate back after 3 seconds
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [testResultUuid, navigate]);

    const handleDetailInfoClick = (uuid) => {
        const detailedData = data.find(item => item.uuid === uuid);
        setModalData([detailedData]);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (loading) {
        return (
            <Container fluid style={{ position: 'relative', padding: '0 2%' }}>
                <Skeleton count={10} />
            </Container>
        );
    }

    return (
        <Container fluid style={{ position: 'relative', padding: '0 2%' }}>
            {error && (
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}
            <Card className="shadow">
                <Card.Header>
                    <p className="text-dark m-0 fw-bold">Test Result Details</p>
                </Card.Header>
                <Card.Body>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="6">
                            Show
                        </Form.Label>
                        <Col sm="6">
                            <Form.Control
                                as="select"
                                value={recordsToShow}
                                onChange={e => setRecordsToShow(e.target.value)}
                                custom
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Table bordered hover responsive className="text-black">
                        <thead>
                        <tr>
                            <th>Question</th>
                            <th>Is Correct</th>
                            <th>Level</th>
                            <th className="text-end">Detail Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.slice(0, recordsToShow).map((item, index) => (
                            <tr key={index}>
                            <td>{item.question_text}</td>
                                <td style={{color: item.is_correct ? 'green' : 'red'}}>
                                    {item.is_correct ? 'Yes' : 'No'}
                                </td>
                                <td>{item.difficulty}</td>
                                <td className="text-end">
                                    <Button
                                        variant="dark"
                                        onClick={() => handleDetailInfoClick(item.uuid)}
                                    >
                                        Detail Info
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <DetailInfoModal show={showModal} handleClose={handleCloseModal} data={modalData} />
        </Container>
    );
};

export default DataTableDetailed;
