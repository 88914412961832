import {Breadcrumb, Container} from 'react-bootstrap';

const BreadcrumbNav = () => {
    return (
        <Container fluid style={{position: 'relative', padding: '0 2%'}}>
            <Breadcrumb>
                <Breadcrumb.Item active>Test results</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
    );
};

export default BreadcrumbNav;