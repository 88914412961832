import TopBar from "../components/TopBar/TopBar";
import DataTable from "../components/DataTable/DataTable";
import BreadcrumbNav from "../components/BreadcrumbNav/BreadcrumbNav";

const TestResults = () => {
    return (
        <><TopBar/>
            <BreadcrumbNav/>
            <DataTable/>
        </>
    );
};

export default TestResults;