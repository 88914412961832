import React, { useEffect, useState } from 'react';
import LineChart from "../../components/Charts/LineChart";
import TopNavbar from "../../components/TopBar/TopBar";
import { Col, Row } from "react-bootstrap";
import InfoCard from "../../components/card/StatCard";
import DoughnutChartCard from "../../components/Charts/DoughnutChart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import "./Dashboard.css";
import axios from "../../api/axios";

const Dashboard = () => {
    const [headerData, setHeaderData] = useState(JSON.parse(localStorage.getItem('headerData')) || {});
    const [lineChartData, setLineChartData] = useState(JSON.parse(localStorage.getItem('lineChartData')) || {});
    const [doughnutChartData, setDoughnutChartData] = useState(JSON.parse(localStorage.getItem('doughnutChartData')) || []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/test_results/6119b3ee-a4fd-4113-83c1-50534301e1ad/dashboard');
                const data = response.data;

                const headerData = data.header;
                setHeaderData(headerData);
                localStorage.setItem('headerData', JSON.stringify(headerData));

                const chartData = {
                    labels: data.line_chart.map(item => item.month_name),
                    totalTests: data.line_chart.map(item => item.total_tests),
                    passedTests: data.line_chart.map(item => item.passed_tests),
                    failedTests: data.line_chart.map(item => item.failed_tests),
                };
                setLineChartData(chartData);
                localStorage.setItem('lineChartData', JSON.stringify(chartData));

                const doughnutData = [data.pie_chart.passed_tests, data.pie_chart.checking_tests, data.pie_chart.failed_tests];
                setDoughnutChartData(doughnutData);
                localStorage.setItem('doughnutChartData', JSON.stringify(doughnutData));
            } catch (error) {
                console.error("Error fetching data from backend", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <TopNavbar />
            <div className="container-fluid">
                <div className="d-sm-flex justify-content-between align-items-center mb-4">
                    <h3 className="text-dark mb-0">Dashboards</h3>
                    {/*<button type="button" className="btn btn-dark">*/}
                    {/*    Generate Results <FontAwesomeIcon icon={faDownload} />*/}
                    {/*</button>*/}
                </div>
                <Row>
                    <Col sm={6} md={3}>
                        <InfoCard title="Tests Passing Percentage" value={headerData.tests_passing_percentage} type="progress" icon="chart" />
                    </Col>
                    <Col sm={6} md={3}>
                        <InfoCard title="Average Tests Passing Mark" value={headerData.tests_average_passing_mark} type="percentage" icon="list" />
                    </Col>
                    <Col sm={6} md={3}>
                        <InfoCard title="Expert Category" value={headerData.expert_category} type="text" icon="database" />
                    </Col>
                    <Col sm={6} md={3}>
                        <InfoCard title="Total Tests Completed" value={headerData.total_tests_completed_percentage} type="progress" icon="chart" />
                    </Col>
                </Row>
                <Row>
                    <Col lg={8}>
                        <LineChart chartData={lineChartData} />
                    </Col>
                    <Col lg={4}>
                        <DoughnutChartCard data={doughnutChartData} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Dashboard;
