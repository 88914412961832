import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { ReactComponent as ReactLogo } from '../../assets/svg/wslogo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const TopNavbar = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Navbar bg="dark" expand="lg" className="me-auto mb-4 shadow" data-bs-theme="dark">
            <Container className="mw-100">
                <Navbar.Brand href="/dashboard"><ReactLogo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="/tests">Tests</Nav.Link>
                        <Nav.Link href="/test-results">Test results</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <NavDropdown title="User" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default TopNavbar;
