import axios from "../api/axios";

const fetchData = async () => {
    try {
        const response = await axios.get('/test_results/');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return [];
    }
};

export default fetchData;
