import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import TestList from "./Tabs/TestList";
import TestResults from "./Tabs/TestResults";
import Dashboard from "./Tabs/Dashboards/Dashboards";
import TestDetail from "./Tabs/TestDetail";
import TestQuestions from "./Tabs/TestQuestions/TestQuestions";
import CompletionPage from "./Tabs/CompletionPage/CompletionPage";
import TestResultDetail from "./Tabs/TestResultDetail/TestResultDetail";
import Login from "./components/Login/Login";
import PrivateRoute from "./routes/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import Calendar from "./Tabs/Calendar/CalendarPage";

function App() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/tests" element={<PrivateRoute><TestList /></PrivateRoute>} />
                    <Route path="/tests/:testId" element={<PrivateRoute><TestDetail /></PrivateRoute>} />
                    <Route path="/tests/:testId/testing" element={<PrivateRoute><TestQuestions /></PrivateRoute>} />
                    <Route path="/test-results" element={<PrivateRoute><TestResults /></PrivateRoute>} />
                    <Route path="/test-results/:testResultUuid" element={<PrivateRoute><TestResultDetail /></PrivateRoute>} />
                    <Route path="/completed" element={<PrivateRoute><CompletionPage /></PrivateRoute>} />
                    {/*<Route path="/calendar" element={<Calendar />} />*/}
                    {/*<Route path="/calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />*/}
                    <Route path="*" element={<Navigate to="/tests" />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default App;
