import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import TopNavbar from "../components/TopBar/TopBar";
import axios from "../api/axios";

export const TestDetail = () => {
    const { testId } = useParams();
    const navigate = useNavigate(); // useNavigate для навигации
    const [testDetails, setTestDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTestDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/tests/${testId}`);
                const data = await response.data;
                setTestDetails(data);
                setIsLoading(false);
            } catch (error) {
                setError(`Failed to fetch test details: ${error.message}`);
                setIsLoading(false);
            }
        };
        fetchTestDetails();
    }, [testId]);

    const handleStartTest = () => {
        navigate(`/tests/${testId}/testing`); // Навигация к началу теста
    };

    if (error) {
        return <>
            <TopNavbar/>
            <Container><Alert variant="danger">Error: {error}</Alert></Container>
        </>;
    }

    return (
        <>
            <TopNavbar/>
            <Container className="mt-5">
                <Row className="justify-content-md-center align-items-center">
                    <Col md={6}>
                        {isLoading ? (
                            <div className="text-center">Loading...</div>
                        ) : (
                            <div>
                                <h1>{testDetails.name}</h1>
                                <p><strong>Category:</strong> {testDetails.category_name}</p>
                                <p><strong>Subcategory:</strong> {testDetails.subcategory_name}</p>
                                <p><strong>Time:</strong> {testDetails.duration}</p>
                                <p><strong>Difficulty:</strong> {testDetails.difficulty}</p>
                                <Button variant="dark" className="mt-2" onClick={handleStartTest}>Start Test</Button>
                            </div>
                        )}
                    </Col>
                    <Col md={6}>
                        <img src="https://avatars.mds.yandex.net/get-marketpic/8963057/pic276d0794daf3a27750b100cb44e24150/orig" alt="Test" className="img-fluid"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Alert variant="warning" className="mt-4">
                            Once the test begins, the timer cannot be paused or stopped until the test is complete.
                        </Alert>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default TestDetail;
