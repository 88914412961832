import React from 'react';
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './LineChart.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = ({ chartData, isLoading }) => {
    const data = {
        labels: chartData.labels || [],
        datasets: [
            {
                label: 'Total Tests',
                data: chartData.totalTests || [],
                fill: true,
                borderColor: '#4e73df',
                tension: 0.4
            },
            {
                label: 'Tests Passed',
                data: chartData.passedTests || [],
                fill: true,
                borderColor: '#1cc88a',
                tension: 0.4
            },
            {
                label: 'Tests Failed',
                data: chartData.failedTests || [],
                fill: true,
                borderColor: '#e74a3b',
                tension: 0.4
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom'
            }
        },
        scales: {
            y: {
                beginAtZero: true
            },
            x: {
                grid: {
                    drawBorder: false,
                    color: function(context) {
                        return context.tick && context.tick.value % 2 ? 'rgba(0, 0, 0, 0.1)' : 'transparent';
                    },
                    borderDash: [5, 5]
                }
            }
        }
    };

    return (
        <Card className="mb-4 shadow-sm">
            <Card.Header as="h6" className="rounded-header text-dark fw-bold">Test Overview</Card.Header>
            <Card.Body>
                {isLoading ? <Skeleton height={400} /> : <Line data={data} options={options} />}
            </Card.Body>
        </Card>
    );
};

export default LineChart;
